<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular mx-4">
            <li>
              <span>Solutions</span>
            </li>
            <li>
              <span>AIMO</span>
            </li>
            <li>
              <span>Marketing channel form</span>
            </li>
            <li class="ProximaNovaBold">Model results</li>
          </ul>
        </div>

        <b-container fluid class="my-5">
          <b-row>
            <b-col
              class="mb-5 h-100"
              v-for="plan in plans"
              :key="plan.id"
              :class="[plan.id !== activePlan ? 'mt-50' : '']"
              cols="12"
              lg="4"
            >
              <div class="Mask">
                <div class="mask-title">{{ plan.title }}</div>
                <div
                  :class="[
                    plan.id == 2 ? 'active-mask-subtitle' : 'mask-subtitle',
                  ]"
                >
                  {{ plan.subtitle }}
                </div>
                <img :src="plan.imageUrl" class="mask-image" />
                <div class="mask-list">
                  <ul class="p-0">
                    <li
                      v-for="(stats,index) in plan.statics"
                      :key="index"
                      class="d-flex justify-content-between mb-5"
                    >
                      <div class="mask-list-item">
                        <i class="far fa-check-circle small mr-3"></i
                        >{{ stats.name }}
                      </div>
                      <div class="mask-list-score">{{ stats.value }}</div>
                    </li>
                  </ul>
                </div>
                <div class="mask-cta text-right">
                  <button
                    color="primary isi"
                    class="btn-effect"
                    size="button--size-m"
                    @click="selectActivePlan(plan)"
                    :class="[
                      plan.id == activePlan
                        ? 'active-btn-effect'
                        : 'inActive-btn-effect',
                    ]"
                  >
                    Select<i
                      class="fas fa-arrow-right ml-5 pl-4 d-flex"
                      style="align-items: center"
                    ></i>
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "results",
  data() {
    return {
      activePlan: 2,
      plans: [
        {
          id: 1,
          title: "Lite Plan",
          subtitle: "Our Basic plan",
          imageUrl: require("@/assets/group-28.png"),
          statics: [
            { name: "R2 Test", value: "0.85" },
            { name: "Mean Actual", value: "0.85" },
            { name: "Testing RMSE", value: "0.85" },
            { name: "Mean Pred", value: "0.85" },
            { name: "Actual std", value: "0.85" },
            { name: "Predicted std", value: "0.85" },
            { name: "Mape Test", value: "0.85" },
          ],
        },
        {
          id: 2,
          title: "Elite Plan",
          subtitle: "Our recommended plan",
          imageUrl: require("@/assets/group-26.png"),
          statics: [
            { name: "R2 Test", value: "0.85" },
            { name: "Mean Actual", value: "0.85" },
            { name: "Testing RMSE", value: "0.85" },
            { name: "Mean Pred", value: "0.85" },
            { name: "Actual std", value: "0.85" },
            { name: "Predicted std", value: "0.85" },
            { name: "Mape Test", value: "0.85" },
          ],
        },
        {
          id: 3,
          title: "Platinum Plan",
          subtitle: "Our pro plan",
          imageUrl: require("@/assets/group-29.png"),
          statics: [
            { name: "R2 Test", value: "0.85" },
            { name: "Mean Actual", value: "0.85" },
            { name: "Testing RMSE", value: "0.85" },
            { name: "Mean Pred", value: "0.85" },
            { name: "Actual std", value: "0.85" },
            { name: "Predicted std", value: "0.85" },
            { name: "Mape Test", value: "0.85" },
          ],
        },
      ],
    };
  },
  methods: {
    selectActivePlan(plan) {
      if (plan.id === this.activePlan) {
        sessionStorage.setItem('selectedPlan',JSON.stringify(plan))
        this.$router.push("/solutions/AIMO/stats");
      } else {
        this.activePlan = plan.id;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  height: 100%;
}
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}

.Mask {
  max-width: 456px;
  width: 100%;
  height: 100%;
  padding: 32px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.mt-50 {
  margin-top: 50px;
}

.mask-title {
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #222a37;
}
.mask-subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
}
.mask-list {
  margin-top: 76px;
}
.mask-list .mask-list-item {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
}
.mask-list .mask-list-score {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
}

.mask-image {
  top: -9%;
  right: -9%;
  position: absolute;
  z-index: -1;
}

.active-mask-subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #55ea76;
}

.btn-effect {
  /* margin: 70px 0px 20px 65px; */
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  border: 2px solid black;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  border-radius: 5px;
  background-color: white;
}

.inActive-btn-effect {
  background-color: white;
}

.active-btn-effect {
  background-color: #050505;
  color: #f3f3f3;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}
</style>
